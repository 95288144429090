import React, { useState, useEffect ,useContext} from 'react';
import {useParams } from 'react-router-dom'; // Correct import for useParams
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import {faHeart} from '@fortawesome/free-solid-svg-icons'
import { IoStarSharp } from "react-icons/io5";
import { Link ,useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { UserContext } from '../../Context/UserContext';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';  // Importing the icon
import { FaEdit } from 'react-icons/fa';


import ReactImageMagnify from 'react-image-magnify';


// edit
import styled from 'styled-components';
// edit

function ProductDetail() {
    const { id } = useParams(); // Correct usage of useParams
    const { user,  addToCart, addToWish} = useContext(UserContext);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [product, setProduct] = useState(null);
    const [colorProducts,setColorProducts] = useState([]);
    const [sizeProducts,setSizeProducts] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [adminReviews,setAdminReviews] = useState([]);
    const [error, setError] = useState();
    const [averageRating, setAverageRating] = useState(0);
    const [mainImage, setMainImage] = useState([]);
    const [mainMedia, setMainMedia] = useState([]); // Set the initial media to be displayed
   
    // Function to handle media change when a thumbnail is clicked
    
    

    const isImage = (media) => {
        return media.endsWith('.jpg') || media.endsWith('.jpeg') || media.endsWith('.png') || media.endsWith('.gif') || media.endsWith('.webp') || media.endsWith('.jfif');
    };

    const isVideo = (media) => {
        return media.endsWith('.mp4') || media.endsWith('.mov') || media.endsWith('.avi') || media.endsWith('.mkv');
    };
    
    const navigate = useNavigate();
    useEffect(() => {
        fetch(`https://waltzify.com/api/Fetch_Products.php?id=${id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok ${response.statusText}`);
                }
                return response.json();
            })
            .then(data => {
                if (data.error) {
                    throw new Error(data.error);
                }
                setProduct(data); 
                setRelatedProducts(data.related_products || []);
                setReviews(data.reviews);
                setColorProducts(data.color_products || [])
                setSizeProducts(data.size_products || [])
                setMainImage(data.img1);
                setMainMedia(data.img1);
                setAdminReviews(data.adminReviews);
                if (data.reviews.length > 0) {
                    const totalRating = data.reviews.reduce((sum, review) => {
                        console.log('Review rating:', review.rating); // Log each rating
                        return sum + Number(review.rating); // Ensure rating is treated as a number
                    }, 0); // Ensure the initial sum is a number
                    const average = totalRating / data.reviews.length;
                    setAverageRating(average);
                    console.log('Total Rating:', totalRating);
                    console.log('Average Rating:', average);
                } else {
                    setAverageRating(0);
                }
            })
            .catch(error => {
                setError(error);
            });
    }, [id]);
    

    
   const handleCart = () => {
        if(!user)
        {
            navigate('/Login');
        }
        else
        {
            addToCart(product);
        }
        
    };
    const handleWish = () => {
        if(!user)
            {
                navigate('/Login');
            }
        else
        {
            addToWish(product);
        }
    }
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const handleBuyNow = () => {
        if (!user) {
            //setPendingAddToCartProduct(product);
            navigate('/Login');
        }
        else
        {
            addToCart(product); // Add the product to the cart
            navigate('/checkout', { state: { product: product } }); // Navigate to checkout page with product details

        }
    };
    if (!product) {
        return <p>Loading...</p>;
    }
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString('en-IN', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    };
    const handleReview = () => {
        if (!user) {
            //setPendingAddToCartProduct(product);
            navigate('/Login');
        }
        else
        {
           
            navigate(`/reviewproduct/${id}`); // Navigate to checkout page with product details

        }
    };
    
    const discountedPrice = product.p_price - (product.p_price * (product.discount / 100));
    const stockColor = product.Stock === 'In Stock' ? 'text-green-500' : 'text-red-500';


    //const displayedPrice = selectedSize ? selectedSize.price : discountedPrice;

    
    const combinedReviews = [
        ...product.reviews.map(review => ({ ...review, isAdmin: false })),
        ...product.adminReviews.map(review => ({ ...review, isAdmin: true }))
      ];

    //   edit
     const ImageContainer = styled.div`
          position: relative;
          width: 100%;
          max-width: 600px; 
          margin: auto;
          height:30rem;
          object-fit:contain;
          aspect-ratio:auto;
        `; 
        
       
    //   edit

    const rating ={
        width:"20px",
        height:"20px",
    }
    const getYouTubeVideoId = (url) => {
        const regex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/;
        const match = url.match(regex);
        return match ? match[1] : null;
    };
    const formatDescription = (description) => {
        // Use regex to split on spaces, while keeping punctuation
        return description.split(/(\s+)/).map((word, index) => {
            // Remove punctuation from the word for the check
            const strippedWord = word.replace(/[.,!?-]/g, '');
    
            // If the word is fully uppercase, wrap it in <strong> for bold effect
            if (strippedWord === strippedWord.toUpperCase() && strippedWord.length > 1) {
                return <strong key={index}>{word}</strong>;
            }
            return word;
        }).reduce((prev, curr) => [prev, curr]); // Maintain original spaces
    };
 
    

    
    const formatKeyFeatures = (keyFeatures) => {
        if (!keyFeatures) return null; // Handle case where keyFeatures is empty or undefined
    
        // Split by newlines to create custom symbols for each line
        return (
            <ul>
                {keyFeatures.split('\n').map((line, index) => (
                    <li key={index} className="text-sm">
                        {line.trim() === '' ? (
                            <>&nbsp;</> // Show a space for empty lines
                        ) : (
                            <>
                                {/* Custom symbol instead of bullet points */}
                                <span role="img" aria-label="bullet" style={{ marginRight: '0.5rem', fontSize: '0.5rem' }}>⚫</span>
                                {line.split(/(\s+)/).map((word, wordIndex) => {
                                    const strippedWord = word.replace(/[.,!?-]/g, '');
    
                                    // If the word is fully uppercase, wrap it in <strong> for bold effect
                                    if (strippedWord === strippedWord.toUpperCase() && strippedWord.length > 1) {
                                        return <strong key={wordIndex}>{word}</strong>;
                                    }
                                    return word;
                                })}
                            </>
                        )}
                    </li>
                ))}
            </ul>
        );
    };
    
    
    
    const handleMediaChange = (media) => {
        setMainMedia(media);
    };
    

    
    
    return (
                 <div className='py-[3rem] px-[1rem] lg:px-[2rem] lg:mt-[10vw] mt-[45vw] '>
                     <div className='flex flex-col lg:flex-row gap-[4rem]'>
                     
                         {/* Render product images and description */}
                         <div className='border-2 h-[43rem] lg:h-[43rem] border-orange-500 rounded-xl py-[1rem] lg:px-[2rem] flex gap-4 flex-col lg:w-[38rem] object-contain aspect-auto'>
                            <FavoriteIcon fontSize="large" className='text-red-500 text-2xl transition-all ease-in-out hover:text-5xl cursor-pointer ml-auto'onClick={handleWish}/>
                       
                         <div className='relative'>
                            
                                 
                            
                             <div className='flex justify-center items-center object-contain '>
                                   
                         
                         {/* edit */}
                         {mainMedia && isImage(mainMedia) ? (
                                  <ImageContainer>
                 <ReactImageMagnify {...{
                            smallImage: {
                                alt: product.pname,
                                isFluidWidth: true,
                                src: `https://waltzify.com/api/Products/${mainMedia}` // Display active image
                            },
                            largeImage: {
                                src: `https://waltzify.com/api/Products/${mainMedia}`,
                                width: 1000,
                                height: 1000,
                                
                            },
                            enlargedImageContainerDimensions: {
                                width: '130%',
                                height: '80%'
                            }
                        }} />
             </ImageContainer>

                 ) :  (
                    <div className="youtube-video-container">
                        <iframe
                            width="100%"
                            height="400"
                            src={mainMedia} // YouTube video URL
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                )}
            </div>

                {/* edit */}


            {/* Thumbnail section */}
          <div className='flex justify-evenly items-center'>
    {[product.img1, product.img2, product.img3,product.img4,product.img5, product.img6, product.img7, product.youtubeLink].map((media, index) => (
        <div 
            key={index} 
            className='relative w-[4rem] h-[4rem] lg:mt-[3rem] lg:h-[5rem] lg:w-[8rem] border-2 border-black rounded-xl cursor-pointer object-contain aspect-auto' 
            onClick={() => {
                // Handle click based on the type of media
                if (isImage(media)) {
                    handleMediaChange(media); // If it's an image, change to that image
                } else if (media === product.youtubeLink && media && media.trim() !== '') {
                    handleMediaChange(media); // If it's a valid YouTube link, handle it accordingly
                } else {
                    handleMediaChange(product.img1); // Fallback to the main image (or any main image you want)
                }
            }}
        >
            {isImage(media) ? (
                <img 
                    src={`https://waltzify.com/api/Products/${media}`} 
                    alt={`${product.pname} ${index + 1}`} 
                    className='w-full h-full object-contain aspect-auto' 
                />
            ) : (media === product.youtubeLink && media && media.trim() !== '') ? (
                <div className='relative w-full h-full'>
                    
                    <img
                        src={`https://img.youtube.com/vi/${getYouTubeVideoId(media)}/maxresdefault.jpg`} 
                        alt="YouTube Video"
                        className='w-full h-full object-cover'
                    />
                    {/* Play icon overlay */}
                    <div className='absolute inset-0 flex items-center justify-center'>
                        <PlayArrowIcon fontSize="large" className='text-white bg-black bg-opacity-50 rounded-full p-1' />
                    </div>
                </div>
            ) : (
                // Here we check if media is null or empty and then display the fallback
                <img 
                    src={`https://waltzify.com/api/Products/${product.img1}`} // Show the main product image as fallback
                    alt="Fallback"
                    className='w-full h-full object-contain aspect-auto' 
                />
            )}
        </div>
    ))}
</div>
</div>


           {/*  <div className='flex justify-evenly items-center'>
    {[product.img1, product.img2, product.img3, product.youtubeLink].map((media, index) => (
        <div key={index} className='relative w-[4rem] h-[4rem] lg:h-[8rem] lg:w-[8rem] border-2 border-black rounded-xl cursor-pointer object-contain aspect-auto' onClick={() => handleMediaChange(media)}>
            {isImage(media) ? (
                <img src={`https://waltzify.com/api/Products/${media}`} alt={`${product.pname} ${index + 1}`} className='w-full h-full object-contain aspect-auto' />
            ) : isVideo(media) ? (
                <div className='relative w-full h-full'>
                    Video element as thumbnail
                    <video className='w-full h-full object-cover'>
                        <source src={`https://waltzify.com/api/Products/${media}`} type="video/mp4" />
                    </video>

                    Play icon overlay
                    <div className='absolute inset-0 flex items-center justify-center'>
                        <PlayArrowIcon fontSize="large" className='text-white bg-black bg-opacity-50 rounded-full p-1' />
                    </div>
                </div>
            ) :  media === product.youtubeLink && product.youtubeLink === '' ? (
                <div className='relative w-full h-full'>
                    YouTube thumbnail
                    <img
                        src={`https://img.youtube.com/vi/${getYouTubeVideoId(media)}/maxresdefault.jpg`} 
                        alt="YouTube Video"
                        className='w-full h-full object-cover'
                    />

                    Play icon overlay
                    <div className='absolute inset-0 flex items-center justify-center'>
                        <PlayArrowIcon fontSize="large" className='text-white bg-black bg-opacity-50 rounded-full p-1' />
                    </div>
                </div>
            ) : (
              
              
                <img 
                    src={`https://waltzify.com/api/Products/${media}`} // Use the same media variable
                    alt="Fallback"
                    className='w-full h-full object-contain aspect-auto' 
                />
            
            )}
        </div>
    ))}
</div> */}

                 

                
                </div>
                <div className='flex flex-col gap-[1rem]'>
                    <h1 className='text-2xl font-semibold lg:w-[40rem]'>{product.pname}</h1>
                    <div className='flex gap-[2rem] items-end'>
                    <p className='text-green-500 text-2xl font-bold'>₹{discountedPrice.toFixed(2)}</p>
                    {/* <p className='text-green-500 text-2xl font-bold'>₹{(discountedPrice *  (1 + product.igstn / 100)).toFixed(2)}</p> */}
                       {/*  <p className='text-red-500 text-sm line-through'>₹{(product.p_price *  (1 + product.igstn / 100)).toFixed(2)}</p> */}
                        <p className='text-red-500 text-sm line-through'>₹{product.p_price}</p>
                        <p className='text-red-500 text-sm'>{product.discount == 0 ? 'No discount' : `${product.discount}% off`}
                        </p>
                        {/* {selectedSize ? (
                            <p className='text-red-500 text-xl line-through'>₹{product.p_price}</p>
                        ) : (
                            product.p_price && (
                                <p className='text-red-500 text-xl line-through'>₹{product.p_price}</p>
                            )
                        )} */}
                    </div>
                    {/* Render rating */}
                    <div className='flex gap-[2rem]'>
                    
                    <div className='text-yellow-500 flex-wrap items-center text-sm'>
                    {[...Array(5)].map((_, i) => (
                            i <Math.floor(averageRating)? <StarOutlinedIcon key={i} style={rating}/> : <StarBorderOutlinedIcon key={i} />
                        ))}
                        <span className='text-black ml-[1rem] text-sm'>({averageRating.toFixed(1)} Rating)</span>
                        
                        
                        </div>
                        <p className='text-gray-500 text-xs'>SKU : {product.SKU}</p>
                        <p className="text-black text-xs">
                            <span>Stock : </span>
                            <span className={`font-bold ${stockColor}`}>{product.Stock}</span>
                        </p>
                        
                        {/* Render review count */}
                    </div>
                    {/* Render available offers */}
                    <div className='flex flex-col gap-[1rem] text-xl'>
                      
                <p className='text-2xl font-bold'>Key Features:</p>
                                    <div className=''>
                        {/* Rendering the formatted key features */}
                        <p className='list-disc text-justify'>
                            {formatKeyFeatures(product.keyFeatures)}
                        </p>
                    </div>
                        {/* <div className='flex items-center gap-2'>
                        <LoyaltyOutlinedIcon/>
                        <p className='text-sm'>Bank OfferGet ₹50 instant discount on first Flipkart UPI transaction on order of ₹200 and above</p>
                    </div>
                    <div className='flex items-center gap-2'>
                        <LoyaltyOutlinedIcon/>
                        <p className='text-sm'>Bank OfferGet ₹50 instant discount on first Flipkart UPI transaction on order of ₹200 and above</p>
                    </div>
                    <div className='flex items-center gap-2'>
                        <LoyaltyOutlinedIcon/>
                        <p className='text-sm'>Bank OfferGet ₹50 instant discount on first Flipkart UPI transaction on order of ₹200 and above</p>
                    </div> */}

                    {/*color*/}
                    {colorProducts.some(colorProduct => colorProduct.color) && (
    <>
        <p className='text-xl font-semibold'>Colors:</p>
        <div className='grid grid-cols-3 lg:grid-cols-5 gap-[1rem]'>
            {colorProducts.map((colorProduct, index) => (
                colorProduct.color && (
                    <div key={index} className='flex flex-col items-center'>
                        <Link to={`/WI/${colorProduct.Id}`}>
                            <img 
                                className='cursor-pointer w-[6rem] h-[5rem] border-2 border-black rounded-xl object-contain aspect-auto' 
                                src={`https://waltzify.com/api/Products/${colorProduct.img1}`} 
                                alt={colorProduct.color} 
                            />
                        </Link>    
                        <p className='text-black'>{colorProduct.color}</p>
                    </div>
                )
            ))}
        </div>
    </>
)}

                    {/* <p className='text-xl font-semibold'>Colors:</p>
                    <div className='grid grid-cols-3 lg:grid-cols-5 gap-[1rem]'>
                            
                            {colorProducts.map((colorProduct, index) => (
                                <div key={index} className='flex flex-col items-center'>
                                    
                                    <Link to = {`/WI/${colorProduct.Id}`}>
                                    <img className='cursor-pointer w-[6rem] h-[5rem] border-2 border-black rounded-xl object-contain aspect-auto' 
                                        src={`https://waltzify.com/api/Products/${colorProduct.img1}`} 
                                        alt={colorProduct.color} />
                                    </Link>    
                                    <p className='text-black'>{colorProduct.color}</p>
                                </div>
                            ))}
                       
                    </div> */}
                {/*size of products */}   
                {sizeProducts.some(sizeProduct => sizeProduct.size) && (
    <>
        <p className='text-xl font-semibold'>Sizes:</p>
        <div className='flex items-center no-scrollbar p-2 overflow-x-scroll lg:grid lg:grid-cols-4 gap-[1rem]'>
            {sizeProducts.map((sizeProduct, index) => (
                sizeProduct.size && (
                    <div key={index} className='flex flex-wrap gap-[0.5rem]'>
                        <Link to={`/WI/${sizeProduct.Id}`}>
                            <div className='w-[8rem] flex flex-col items-center border-2 border-black rounded-xl py-1 px-2 hover:scale-105 cursor-pointer hover:bg-gray-100 hover:border-orange-500'>
                                <p className=''><span className=''>{sizeProduct.size}</span></p>
                               {/*  <p className='font-bold'>₹{discountedPrice}</p> */}
                            </div>
                        </Link>
                    </div>
                )
            ))}
        </div>
    </>
)}
 
                {/* <p className='text-xl font-semibold'>Sizes:</p>
                <div className='flex items-center no-scrollbar p-2 overflow-x-scroll lg:grid lg:grid-cols-4 gap-[1rem]'>
                    {sizeProducts.map((sizeProduct, index) => (
                    <div key={index} className='flex flex-wrap gap-[0.5rem]'>
                        <Link to = {`/WI/${sizeProduct.Id}`}>
                        <div className='w-[8rem] flex flex-col items-center border-2 border-black rounded-xl py-1 px-2 hover:scale-105 cursor-pointer hover:bg-gray-100 hover:border-orange-500'>

                            <p className=''>Size: {sizeProduct.size}</p>
                            <p className='font-bold'>₹{sizeProduct.p_price}</p>
                        </div>
                        </Link>

                    </div>
                    ))}
                </div> */}
                        
            </div>
                    
                    {/* Render add to cart and wishlist buttons */}
                    <div className='lg:w-[40rem] mt-[3rem] flex flex-col text-xl gap-[2rem] sm:max-sm:text-sm'>
                        <button onClick={handleCart} className='add_to_cart'>Add To Cart</button>
                        <button onClick={handleBuyNow} className='add_to_cart'>Buy Now</button>
                    </div>
                </div>
                
              </div>
            {/* Render about product section */}
            {/* ... */}
            <div className='lg:w-1/2 mt-[2rem] flex flex-col gap-[2rem]'>
                <p className='text-2xl font-bold'>About this item:</p>
                <div className='lg:w-[80rem]'>
                    <ul className='list-disc text-justify'>
                        {/*  {product.map((product,index)=>( */}
                        <p className="mb-4 text-sm" style={{ whiteSpace: 'pre-line' }}> {formatDescription(product.description)}</p>
                        {/*))}*/}
                    </ul>
                </div>
            </div>
            
          
            {/* Render warranty and other sections */}
            <div className='flex flex-wrap gap-[1rem] items-center justify-evenly text-sm lg:text-xl py-2 md:text-xs font-bold mt-2'>
            <div className='flex items-center gap-[0.5rem]'>
                <AssignmentReturnIcon/>
                <p>Return & Exchange</p>
            </div>
            <div className='flex items-center gap-[0.5rem]'>
                <SupportAgentIcon/>
                <p>Customer Service</p>
            </div>
            <div className='flex items-center gap-[0.5rem]'>
                <LocalShippingOutlinedIcon/>
                <p>Shipping and Delivery</p>
            </div>
            <div className='flex items-center gap-[0.5rem]'>
                <SecurityOutlinedIcon/>
                <p>Warranty</p>
            </div>
        </div>
            {/* ... */}
            {/* Render customer reviews */}
            <div className='my-[2rem] py-[1.5rem] border-t-2'>
    <p className='text-4xl'>Customer Reviews:</p>
    <div className='flex flex-col mt-[2rem]'>
             <p className='text-3xl'>Review This Product:</p>
                <p className='text-xl'>Share your thoughts with other customers</p> 
                <button  onClick={handleReview} className='w-[10rem] mt-[1rem] rounded-lg text-orange-500 border-orange-500 border-2 py-1 px-3 hover:text-white hover:bg-orange-500 transition-all ease-in-out'>Write a Review</button>            
    </div>
        
  <div className='mt-[2rem] flex gap-[2rem] overflow-x-scroll no-scrollbar'>
  {combinedReviews.map((review, index) => (
    <div key={index} className='border-2 rounded-xl w-[23rem] flex-shrink-0 flex flex-col px-[1rem] py-[0.5rem]'>
      {/* Render review details */}
      <div className='flex items-center gap-2 text-xl'>
        <AccountCircleOutlinedIcon />
        <p className='font-bold'>{review.name}</p>
        <span className='bg-green-600 flex items-center text-sm text-white p-0 rounded-sm' style={rating}>
          {review.rating}  
          <span style={{ fontSize: '0.8rem'}}> {/* Adjust line-height if needed */}
      <IoStarSharp />
    </span>
          </span>
      </div>
      <div className='flex items-center gap-1 mt-[0.5rem]'>
        <div className='text-yellow-500 text-xs'>
          {/* Render stars dynamically based on review.rating */}
          {[...Array(5)].map((_, i) => (
            i < review.rating ? <StarOutlinedIcon key={i} /> : <StarBorderOutlinedIcon key={i} />
          ))}
          <p className='text-black font-bold text-sm'>
            {review.isAdmin ? review.reviewTitle : review.heading}
          </p>
        </div>
      </div>
      <p className='text-sm font-thin'>{`${formatDate(review.timestamp)}`}</p>
      <p className='mt-3 font-bold text-justify'>{review.review}</p>
      <div className='flex'>
        {review.isAdmin ? (
          <>
            <img className='mt-3 w-[5rem] h-[5rem] object-contain aspect-auto' src={`https://waltzify.com/api/Review/${review.img1}`} alt={review.title} />
            <img className='mt-3 w-[5rem] h-[5rem] object-contain aspect-auto' src={`https://waltzify.com/api/Review/${review.img2}`} alt={review.title} />
            <img className='mt-3 w-[5rem] h-[5rem] object-contain aspect-auto' src={`https://waltzify.com/api/Review/${review.img3}`} alt={review.title} />
            <img className='mt-3 w-[5rem] h-[5rem] object-contain aspect-auto' src={`https://waltzify.com/api/Review/${review.img4}`} alt={review.title} />
          </>
        ) : (
          <>
            <img className='mt-3 w-[5rem] h-[5rem] object-contain aspect-auto' src={`https://waltzify.com/api/User_Review/${review.img}`} alt={review.title} />
            <img className='mt-3 w-[5rem] h-[5rem] object-contain aspect-auto' src={`https://waltzify.com/api/User_Review/${review.img}`} alt={review.title} />
            <img className='mt-3 w-[5rem] h-[5rem] object-contain aspect-auto' src={`https://waltzify.com/api/User_Review/${review.img}`} alt={review.title} />
            <img className='mt-3 w-[5rem] h-[5rem] object-contain aspect-auto' src={`https://waltzify.com/api/User_Review/${review.img}`} alt={review.title} />
          </>
        )}
      </div>
    </div>
  ))}
</div>


</div> 

{/* Render related products */}
<div className='flex flex-col gap-[1rem] py-[3rem]'>
    <div className='text-center lg:text-left mb-[2rem]'>
        <p className='text-4xl font-bold'>Related Products</p>
        <p>Below are some suggested items</p>
    </div>
    
     <p className='lg:hidden lg:mx-[2rem] bg-orange-500 px-[1rem] py-2 rounded-bl-2xl text-white text-sm lg:text-2xl'>Selected Category</p>
                <div className=''>
                    <div className='px-1 lg:px-[2.5rem] py-[2rem] place-items-center grid gap-[1rem] lg:grid-cols-4 grid-cols-2 items-center justify-between lg:gap-2'>
                        {relatedProducts.map((relatedProduct, index) => {
                            const discountedPrice = relatedProduct.p_price - (relatedProduct.p_price * (relatedProduct.discount / 100));
                            
                            return(
                                <Link
                                key={index}
                                to={{
                                  pathname: `/WI/${relatedProduct.Id}`,
                                  state: { product: product }
                                }}
                              >
                                <div className='lg:mb-5 shadow-xl hover:border-orange-500 group p-1 w-[10rem] lg:w-[18rem] lg:p-[1rem] border-2 rounded-xl cursor-pointer lg:h-[23rem]'>
                                    <div className='flex justify-between items-center'>
                                        <p className='text-xs bg-orange-500 text-white p-2 rounded-xl'> {relatedProduct.discount > 0 ? `Save ${relatedProduct.discount}%` : 'Best Price'}</p>
                                        
                                    </div>
                                    {/* edit */}
                                    <img className='w-[9rem] h-[7rem] lg:h-[11rem] p-[1rem] lg:w-full rounded-xl object-contain aspect-auto' src={`https://waltzify.com/api/Products/${relatedProduct.img1}`} alt="" />
                                    {/* edit */}
                                    <div className='hidden lg:block mt-[0.5rem] h-[2rem]'>
                                        <p className='hidden group-hover:block bg-orange-500 text-white text-center mx-[1rem] rounded-xl'>Quick View</p>
                                    </div>
                                    <div className='px-[0.2rem]'>
                                    <p className='font-bold text-sm'>{relatedProduct.pname.length > 15? `${relatedProduct.pname.slice(0, 15)}...` : relatedProduct.pname}</p>
                                        <div className='flex gap-3 text-md'>
                                            <p>₹{(discountedPrice * (1 + relatedProduct.igstn / 100)).toFixed(2)}</p>
                                            <span className='line-through text-xs my-auto'>₹{(relatedProduct.p_price *  (1 + relatedProduct.igstn / 100)).toFixed(2)}</span>
                                        </div>
                                        <div className='text-yellow-500 text-sm'>
                                  
                                        {[...Array(5)].map((_, i) => (
                                        i < relatedProduct.average_rating ? <StarOutlinedIcon key={i} style={rating}/> : <StarBorderOutlinedIcon key={i} style={rating}/>
                                    ))}
                            
                                </div>
                                    </div>
                                </div>
                            </Link>
                            );
                        })}
                    </div>

                </div>
</div>

        </div>
    );
}

export default ProductDetail;
